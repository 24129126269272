'use strict'
const _ = require('lodash')
const {ViewerJsonInvalidViewModeParamError} = require('./errors')
const setMeshResults = require('./mesh/mesh')
const removeComponentsByViewMode = require('./viewMode/viewMode')

const VIEW_MODES = ['desktop', 'mobile']

module.exports = () => ({
    execute: ({page, pageJson: optionalPageJson, viewMode, shouldCalcMeshInServer}) => {
        if (!VIEW_MODES.includes(viewMode)) {
            throw new ViewerJsonInvalidViewModeParamError(viewMode)
        }

        const pagePromise = optionalPageJson ? Promise.resolve(optionalPageJson) : page

        return pagePromise.then(pageJson => {
            _.set(pageJson, 'metaData', {})
            removeComponentsByViewMode(pageJson, viewMode)

            if (shouldCalcMeshInServer) {
                setMeshResults(pageJson, viewMode)
            }
            return pageJson
        })
    }
})
