'use strict'
const {WixBusinessError} = require('wix-errors')

class ViewerJsonInvalidViewModeParamError extends WixBusinessError {
    constructor(viewMode) {
        super(`Invalid viewMode parameter, received: ${viewMode}`)
    }
}

module.exports = () => ({
    ViewerJsonInvalidViewModeParamError
})
