'use strict'

const _ = require('lodash')
const getMeshResults = require('../gen/mesh')
const functionLibrary = require('../functionLibrary/functions')
const getFlattenStructure = require('../utils/flattenStructure')

module.exports = (pageJson, viewMode) => {
    const isMobile = viewMode === 'mobile'
    try {
        const pageId = pageJson.structure.id
        const flatPage = {
            ...pageJson,
            structure: getFlattenStructure(pageJson.structure, pageId, isMobile)
        }
        const {meshResults} = getMeshResults({
            flatPage,
            isMobileDevice: isMobile,
            isMobileView: isMobile
        }, functionLibrary)
        _.set(pageJson, 'meshResults', meshResults)
    } catch (e) {
        _.set(pageJson, 'meshResults', null) // TODO: report to BI
    }
}
